import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="divine"></a><h2>Divine</h2>
    <a id="new-domains"></a><h3>NEW DOMAINS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a id="artifice-domain"></a><h5><a id="artifice"></a>ARTIFICE DOMAIN</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>Gain +4
bonus on Craft checks. The character casts conjuration (creation)
spells at +1 caster level. (Those with access to both the Artifice and
Creation domains cast conjuration [creation] spells at +3 caster level.)
    <h6>Artifice Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-rope">Animate Rope</a></td>
          <td>Makes a rope move at your command.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wood-shape">Wood Shape</a></td>
          <td>Rearranges wooden objects to suit you.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stone-shape">Stone Shape</a></td>
          <td>Sculpts stone into any shape.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#minor-creation">Minor Creation</a></td>
          <td>Creates one cloth or wood object.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fabricate">Fabricate</a></td>
          <td>Transforms raw materials into finished items.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#major-creation">Major Creation</a></td>
          <td>As minor creation, plus stone and metal.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#hardening">Hardening</a></td>
          <td>Increases hardness of target by 1 per 2 levels.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#true-creation">True Creation</a></td>
          <td>Creates permanent non-magical object.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prismatic-sphere">Prismatic sphere</a></td>
          <td>As prismatic wall, but surrounds on all sides.</td>
        </tr>
      </tbody>
    </table>
    <a id="charm-domain"></a><h5><a id="charm"></a>CHARM DOMAIN</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>The
character can boost his or her Charisma by 4 points once per day.
Activating this power is a free action. The Charisma increase lasts 1
minute.
    <h6>Charm Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#charm-person">Charm Person</a></td>
          <td>Makes one person your friend.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#calm-emotions">Calm Emotions</a></td>
          <td>Calms creatures, negating emotion effects.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#suggestion">Suggestion</a></td>
          <td>Compels subject to follow stated course of action.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "#579eb6"
            }} href="spellsHtoL.html#heroism">Heroism</a></td>
          <td>Gives +2 on attack rolls, saves, skill checks.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#charm-monster">Charm Monster</a></td>
          <td>Makes monster believe it is your ally.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#geas-quest">Geas/Quest</a></td>
          <td>As lesser geas, plus it affects any creature.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#insanity">Insanity</a></td>
          <td>Subject suffers continuous <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#confused">confusion</a>.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#demand">Demand</a></td>
          <td>As sending, plus you can send suggestion.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dominate-monster">Dominate Monster</a></td>
          <td>As dominate person, but any creature.</td>
        </tr>
      </tbody>
    </table>
    <a id="community-domain"></a><h5><a id="community"></a>COMMUNITY DOMAIN</h5>
    <p className="initial"><b>Granted Power: </b>Use calm emotions as a
spell-like ability once per day. Gain a +2 competence bonus on
Diplomacy checks.</p>
    <h6>Community Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bless">Bless</a>
          </td>
          <td>Allies gain +1 on attack rolls and saves against fear.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#status">Status</a></td>
          <td>Monitors condition, position of allies.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prayer">Prayer</a></td>
          <td>Allies +1 bonus on most rolls, enemies &ndash;1 penalty.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#status-greater">Greater Status</a></td>
          <td>Monitors condition, position of allies.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#telepathic-bond">Telepathic Bond</a></td>
          <td>Link lets allies communicate.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heroes-feast">Heroes&rsquo; Feast</a></td>
          <td>Food for one creature/level cures and grants combat
bonuses.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#refuge">Refuge</a></td>
          <td>Alters item to transport its possessor to you.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sympathy">Sympathy</a></td>
          <td>Object or location attracts certain creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-heal">Mass Heal</a></td>
          <td>As heal, but with several subjects.</td>
        </tr>
      </tbody>
    </table>
    <a id="creation-domain"></a><h5><a id="creation"></a>CREATION DOMAIN</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>Cast
conjuration (creation) spells at +2 caster level. (Those with access to
both the Artifice and Creation domains cast conjuration (creation)
spells at +3 caster level.)
    <h6>Creation Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-water">Create Water</a></td>
          <td>Creates 2 gallons/level of pure water.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#minor-image">Minor Image</a></td>
          <td>As silent image, plus some sound.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-food-and-water">Create Food and Water</a></td>
          <td>Feeds three humans (or one horse)/level.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#minor-creation">Minor Creation</a></td>
          <td>Creates one cloth or wood object.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#major-creation">Major Creation</a></td>
          <td>As minor creation, plus stone and metal.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heroes-feast">Heroes&rsquo; Feast</a></td>
          <td>Food for one creature/level cures and grants combat
bonuses.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#permanent-image">Permanent Image</a></td>
          <td>Includes sight, sound, and smell.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#true-creation">True Creation</a></td>
          <td>Creates permanent nonmagical object.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#genesis">Genesis</a></td>
          <td>Creates a small demiplane.</td>
        </tr>
      </tbody>
    </table>
    <a id="darkness-domain"></a><h5><a id="darkness"></a>DARKNESS DOMAIN</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>Free
Blind-Fight feat.
    <h6>Darkness Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscuring-mist">Obscuring Mist</a>
          </td>
          <td>Fog surrounds you.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blindness">Blindness</a></td>
          <td>Makes subject <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinded.</a></td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#blacklight">Blacklight</a></td>
          <td>Creats magical darkness that the caster can see through.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#armor-of-darkness">Armor of Darkness</a></td>
          <td>Shroud obscures caster and grants darkvison and
deflection bonus to AC.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-v">Summon monster V*</a></td>
          <td>Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prying-eyes">Prying Eyes</a></td>
          <td>1d4 +1/level floating eyes scout for you.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#nightmare">Nightmare</a></td>
          <td>Sends vision dealing 1d10 damage, fatigue.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#power-word-blind">Power Word, Blind</a></td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">Blinds</a> creature with
200 hp or less.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#power-word-kill">Power Word, Kill</a></td>
          <td>Kills one creature with 100 hp or less.</td>
        </tr>
        <tr>
          <td colSpan="2" rowSpan="1">*<span style={{
              "fontStyle": "italic"
            }}>only summons 1d3 shadows</span></td>
        </tr>
      </tbody>
    </table>
    <a id="glory-domain"></a><h5><a id="glory"></a>GLORY DOMAIN</h5>
    <p className="initial"><b>Granted Power: </b>Turn undead with a +2
bonus on the turning check and +1d6 to the turning damage roll.</p>
    <h6>Glory Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#disrupt-undead">Disrupt Undead</a>
          </td>
          <td>Deals 1d6 damage to one undead.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bless-weapon">Bless Weapon</a></td>
          <td>Weapon strikes true against evil foes.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#searing-light">Searing Light</a></td>
          <td>Ray deals 1d8/two levels damage, more against undead.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#holy-smite">Holy Smite</a></td>
          <td>Damages and <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinds</a> evil creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#holy-sword">Holy Sword</a></td>
          <td>Weapon becomes +5, deals +2d6 damage against evil.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#bolt-of-glory">Bolt of Glory</a></td>
          <td>Fires a magical bolt that deal damage according to the
target's type and origin.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sunbeam">Sunbeam</a></td>
          <td>Beam <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinds</a> and deals 4d6
damage.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#crown-of-glory">Crown of Glory</a></td>
          <td>The caster is imbued with an aura of celestial
authority,
inspiring awe in all lesser creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gate">Gate</a></td>
          <td>Connects two planes for travel or summoning.</td>
        </tr>
      </tbody>
    </table>
    <a id="liberation-domain"></a><h5><a id="liberation"></a>LIBERATION DOMAIN</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power:</span> The
character gains a +2 morale bonus on all saving throws against
enchantment spells or effects.
    <h6>Liberation Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-fear">Remove Fear</a>
          </td>
          <td>Suppresses fear or gives +4 on saves against fear for
one subject + one per four levels.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-paralysis">Remove Paralysis</a></td>
          <td>Frees one or more creatures from paralysis or slow
effect.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-curse">Remove Curse</a></td>
          <td>Frees object or person from curse.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#freedom-of-movement">Freedom of Movement</a></td>
          <td>Subject moves normally despite impediments.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#break-enchantment">Break Enchantment</a></td>
          <td>Frees subjects from enchantments, alterations, curses,
and petrification.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#greater-dispel-magic">Greater Dispel Magic</a>
          </td>
          <td>As dispel magic, but up to +20 on check.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#refuge">Refuge</a></td>
          <td>Alters item to transport its possessor to you.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mind-blank">Mind Blank</a></td>
          <td>Subject is immune to mental/emotional magic and scrying.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "#579eb6"
            }} href="spellsFtoG.html#freedom">Freedom</a></td>
          <td>Releases creature from imprisonment.</td>
        </tr>
      </tbody>
    </table>
    <a id="madness-domain"></a><h5><a id="madness"></a>MADNESS DOMAIN</h5>
    <p style={{
      "fontWeight": "bold"
    }} className="initial">Granted Power: <span style={{
        "fontWeight": "normal"
      }}>The character gains an Insanity score
equal to half his or her class level. For spellcasting (determining
bonus spells and DCs), the character uses his or her Wisdom score plus
his or her Insanity score in place of Wisdom alone.</span>
      <span style={{
        "fontWeight": "normal"
      }}>For all other purposes, such
as skills and saves, use Wisdom minus Insanity in place of Wisdom. </span>
      <span style={{
        "fontWeight": "normal"
      }}>Once per day, the character
can see and act with the clarity of true madness. Use the
character&rsquo;s Insanity score as a positive rather than a negative
modifier on a single roll involving Wisdom. Choose to use this power
before the roll is made.</span></p>
    <h6>Madness Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <span style={{
              "color": "rgb(0, 0, 0)"
            }}><a href="spellsC.html#lesser-confusion">Confusion, Lesser</a></span>
          </td>
          <td><span style={{
              "fontStyle": "italic"
            }}>One creature acts randomly for one round.</span></td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#touch-of-madness">Touch of Madness</a></td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#dazed">Dazes</a> touched target for
1 round per caster level.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#rage">Rage</a></td>
          <td>Blood frenzy grants target +4 Str, +4 Con and +2 on
Will saves.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#confusion">Confusion</a></td>
          <td>Subjects behave oddly for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#bolts-of-bedevilment">Bolts of Bedevilment</a></td>
          <td>One ray attack per round. Affected creatures are <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#dazed">dazed</a> for 1d3 rounds.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#phantasmal-killer">Phantasmal Killer</a></td>
          <td>Fearsome illusion kills subject or deals 3d6 damage.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#insanity">Insanity</a></td>
          <td>Subject suffers continuous confusion.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#maddening-scream">Maddening Scream</a></td>
          <td>Makes subject race about caterwauling.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#weird">Weird</a></td>
          <td>As phantasmal killer, but affects all within 30 ft.</td>
        </tr>
      </tbody>
    </table>
    <a id="nobility-domain"></a><h5><a id="nobility"></a>NOBILITY DOMAIN</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>The
character has the spell-like ability to inspire allies, giving them a
+2 morale bonus on saving throws, attack rolls, ability checks, skill
checks, and weapon damage rolls. Allies must be able to hear the
character speak for 1 round. Using this ability is a standard action.
It lasts a number of rounds equal to the character&rsquo;s Charisma
bonus and can be used once per day.
    <h6>Nobility Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#divine-favor">Divine
Favor</a>
          </td>
          <td>You gain +1 per three levels on attack and damage rolls.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#enthrall">Enthrall</a></td>
          <td>Captivates all within 100 ft. + 10 ft./level.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-vestment">Magic Vestment</a></td>
          <td>Armor or shield gains +1 enhancement per four levels.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#discern-lies">Discern Lies</a></td>
          <td>Reveals deliberate falsehoods.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#greater-command">Greater Command</a></td>
          <td>As command, but affects one subject/level.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#geas-quest">Geas/Quest</a></td>
          <td>As lesser geas, plus it affects any creature.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repulsion">Repulsion</a></td>
          <td>Creatures can&rsquo;t approach you.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#demand">Demand</a></td>
          <td>As sending, plus you can send suggestion.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#storm-of-vengeance">Storm of Vengeance</a></td>
          <td>Storm rains acid, lightning, and hail.</td>
        </tr>
      </tbody>
    </table>
    <a id="repose-domain"></a><h5><a id="repose"></a>REPOSE DOMAIN</h5>
The Repose domain is similar to the Death domain, but is granted by
good-aligned deities whose clerics are barred from casting evil spells.<span style={{
      "fontWeight": "bold"
    }}>
    </span>
    <p><span style={{
        "fontWeight": "bold"
      }}>Granted Power: </span>The
character may use a death touch once per day. The death touch is a
spell-like ability that is a death effect. The character must succeed
at a melee touch attack against a living creature (using the rules for
touch spells). When the character touches, roll 1d6 per his or her
cleric level. If the total at least equals the creature&rsquo;s current
hit points, it dies.</p>
    <h6>Repose Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#deathwatch">Deathwatch</a>
          </td>
          <td>Reveals how near death subjects within 30 ft. are.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gentle-repose">Gentle Repose</a></td>
          <td>Preserves one corpse.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#speak-with-dead">Speak with Dead</a></td>
          <td>Corpse answers one question/two levels.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#death-ward">Death Ward</a></td>
          <td>Grants immunity to death spells and negative energy
effects.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#slay-living">Slay Living</a></td>
          <td>Touch attack kills subject.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#undeath-to-death">Undeath to Death</a></td>
          <td>Slays 1d4 HD of undead creatures per caster level (max
20d4).</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#destruction">Destruction</a></td>
          <td>Kills subject and destroys remains.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#surelife">Surelife</a></td>
          <td>Protects caster from specified natural death.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wail-of-the-banshee">Wail of the Banshee</a></td>
          <td>Kills one creature/level.</td>
        </tr>
      </tbody>
    </table>
    <a id="rune-domain"></a><h5><a id="rune"></a>RUNE DOMAIN</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power:</span> Free
Scribe Scroll feat.
    <h6>Rune Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#erase">Erase</a>
          </td>
          <td>Mundane or magical writing vanishes.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#secret-page">Secret Page</a></td>
          <td>Changes one page to hide its real content.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#glyph-of-warding">Glyph of Warding</a></td>
          <td>Inscription harms those who pass it.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#explosive-runes">Explosive Runes</a></td>
          <td>Deals 6d6 damage when read.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#lesser-planar-binding">Lesser Planar Binding</a></td>
          <td>Traps extraplanar creature of 6 HD or less until it
performs a task.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#greater-glyph-of-warding">Greater Glyph of
Warding</a></td>
          <td>As glyph of warding, but up to 10d8 damage or 6th-level
spell.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#instant-summons">Instant Summons</a></td>
          <td>Prepared object appears in your hand.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "#579eb6"
            }} href="spellsS.html#symbol-of-death">Symbol of Death</a></td>
          <td>Triggered rune slays nearby creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#teleportation-circle">Teleportation Circle</a></td>
          <td>Circle teleports any creature inside to designated spot.</td>
        </tr>
      </tbody>
    </table>
    <a id="skalykind-domain"></a><h5><a id="skalykind"></a>SKALYKIND DOMAIN</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>Rebuke or
command animals (reptilian creatures and snakes only) as an evil cleric
rebukes or commands undead.
    <p>{`Use this ability a total number of times per day equal to 3 + Charisma
modifier.`}</p>
    <h6>Skalykind Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-fang">Magic Fang</a>
          </td>
          <td>One natural weapon of subject creature gets +1 on
attack and damage rolls.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-trance">Animal Trance&dagger;</a></td>
          <td>Fascinates 2d6 HD of animals.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#greater-magic-fang">Greater Magic Fang</a></td>
          <td>One natural weapon of subject creature gets +1/four
levels on attack and damage rolls (max +5).</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#poison">Poison</a></td>
          <td>Touch deals 1d10 Con damage, repeats in 1 min.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-growth">Animal Growth&dagger;</a></td>
          <td>One animal/two levels doubles in size.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#eyebite">Eyebite</a></td>
          <td>Target becomes <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#panicked">panicked</a>, <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#sickened">sickened</a>, and comatose.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#creeping-doom">Creeping Doom*</a>
          </td>
          <td>Swarms of centipedes attack at your command.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-shapes">Animal Shapes&dagger;</a></td>
          <td>One ally/level polymorphs into chosen animal.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shapechange">Shapechange</a></td>
          <td>Transforms you into any creature, and change forms once
per round.</td>
        </tr>
        <tr>
          <td colSpan="2">&dagger;<span style={{
              "fontStyle": "italic"
            }}>Affects
ophidian or reptilian
creatures only.</span></td>
        </tr>
        <tr>
          <td colSpan="2" rowSpan="1">*<span style={{
              "fontStyle": "italic"
            }}>Composed of tiny snakes</span></td>
        </tr>
      </tbody>
    </table>
    <a id="weather-domain"></a><h5><a id="weather"></a>WEATHER DOMAIN</h5>
    <p className="initial"><b>Granted Power: </b>Survival is a class
skill.</p>
    <h6>Weather Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscuring-mist">Obscuring Mist</a>
          </td>
          <td>Fog surrounds you.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fog-cloud">Fog Cloud</a></td>
          <td>Fog obscures vision.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#call-lightning">Call Lightning</a></td>
          <td>Calls down lightning bolts (3d6 per bolt) from sky.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sleet-storm">Sleet Storm</a></td>
          <td>Hampers vision and movement.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#call-lightning-storm">Call Lightning Storm</a></td>
          <td>As call lightning, but 5d6 damage per bolt.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-winds">Control Winds</a></td>
          <td>Change wind direction and speed.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-weather">Control Weather</a></td>
          <td>Changes weather in local area.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#whirlwind">Whirlwind</a></td>
          <td>Cyclone deals damage and can pick up creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#storm-of-vengeance">Storm of Vengeance</a></td>
          <td>Storm rains acid, lightning, and hail.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      